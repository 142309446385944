<template>
  <div class="management-member-modal-form">
    <b-form>
      <div class="row">
        <div class="col-lg-6">
          <div class="layout_image">
            <b-form-group>
              <b-input-group>
                <div id="preview" class="col-lg-6 mx-lg-2 preview">
                  <img
                    class="m-auto"
                    :src="form.company_logo || defaultLogo"
                    alt="画像アップロード"
                    @click="handleClickImage"
                  />
                </div>
                <label ref="logoImage" for="image-upload" class="button_image"
                  ><b-button variant="light">画像アップロード</b-button>
                  <input
                    id="image-upload"
                    ref="upload-image"
                    class="input-image"
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    @change="onFileChange"
                /></label>
                <span v-if="!!errorMessages.form.company_logo" class="label-error"
                  >{{ errorMessages.form.company_logo }}
                </span>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <div class="col-lg-6">
          <b-form-group
            id="input-group-1"
            label="会社名"
            label-for="companyName"
            class="required m-1"
          >
            <b-input-group>
              <b-form-input
                id="companyName"
                :value="form.company_name"
                type="text"
                :class="status($v.form.company_name)"
                :formatter="formatter"
                @change="setFormValue('company_name', $event)"
              />
              <b-input-group-append v-if="!!errorMessages.form.company_name" class="icon-alert">
                <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
              </b-input-group-append>
            </b-input-group>
            <span v-if="!!errorMessages.form.company_name" class="label-error"
              >{{ errorMessages.form.company_name }}
            </span>
          </b-form-group>
          <b-form-group id="select-group-1" label="業界" label-for="select-1" class="m-1 required">
            <b-form-select
              id="select-1"
              :value="form.company_career"
              class="form-select"
              :options="optionsCareer"
              :class="status($v.form.company_career)"
              @change="setFormValue('company_career', $event)"
            />
            <span v-if="!!errorMessages.form.company_career" class="label-error"
              >{{ errorMessages.form.company_career }}
            </span>
          </b-form-group>
          <b-form-group
            id="input-2"
            label="電話番号"
            label-for="company_phone"
            class="required m-1"
          >
            <b-input-group>
              <b-form-input
                id="company_phone"
                :value="form.company_phone"
                type="text"
                :class="status($v.form.company_phone)"
                :formatter="formatNumberPhone"
                @change="setFormValue('company_phone', $event)"
              />
              <b-input-group-append v-if="!!errorMessages.form.company_phone" class="icon-alert">
                <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
              </b-input-group-append>
            </b-input-group>
            <span v-if="!!errorMessages.form.company_phone" class="label-error"
              >{{ errorMessages.form.company_phone }}
            </span>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <div class="m-1">
            <b-form-group id="input-group-2" label="資本金" label-for="input-2">
              <b-form-input
                id="input-2"
                :value="form.company_capital"
                type="text"
                :formatter="formatNumber"
                @change="setFormValue('company_capital', $event)"
              />
            </b-form-group>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="m-1">
            <b-form-group id="input-group-3" label="ドメイン" label-for="input-3" class="required">
              <b-input-group>
                <b-form-input
                  id="input-3"
                  :value="form.company_domain"
                  type="text"
                  :class="status($v.form.company_domain)"
                  :formatter="formatter"
                  @change="setFormValue('company_domain', $event)"
                />
                <b-input-group-append v-if="!!errorMessages.form.company_domain" class="icon-alert">
                  <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
                </b-input-group-append>
              </b-input-group>
              <span v-if="!!errorMessages.form.company_domain" class="label-error"
                >{{ errorMessages.form.company_domain }}
              </span>
            </b-form-group>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-lg-6">
          <div class="m-1">
            <b-form-group
              id="input-group-4"
              label="メールアドレス"
              label-for="input-4"
              class="required"
            >
              <b-input-group>
                <b-form-input
                  id="input-4"
                  :value="form.email"
                  type="text"
                  :class="status($v.form.email)"
                  :formatter="formatter"
                  autocomplete="email"
                  @change="setFormValue('email', $event)"
                />
                <b-input-group-append v-if="!!errorMessages.form.email" class="icon-alert">
                  <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
                </b-input-group-append>
              </b-input-group>
              <span v-if="!!errorMessages.form.email" class="label-error"
                >{{ errorMessages.form.email }}
              </span>
            </b-form-group>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="m-1">
            <b-form-group
              id="input-group-5"
              label="パスワード"
              label-for="input-5"
              class="required"
            >
              <b-input-group>
                <b-form-input
                  id="input-5"
                  :value="dummyPassword"
                  type="password"
                  autocomplete="new-password"
                  :class="status($v.form.password, false, true)"
                  :formatter="formatter"
                  :disabled="isEditForm"
                  @change="setFormValue('password', $event)"
                  @click="resetDummyPassword"
                />
                <b-input-group-append
                  v-if="status($v.form.password, false, true).invalid"
                  class="icon-alert"
                >
                  <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
                </b-input-group-append>
              </b-input-group>
              <span v-if="status($v.form.password, false, true).invalid" class="label-error"
                >{{ errorMessages.form.password }}
              </span>
            </b-form-group>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-lg-6">
          <div class="m-1">
            <b-form-group id="select-group-2" label="プラン" label-for="select-2" class="required">
              <b-form-select
                id="select-2"
                :value="form.company_package_id"
                class="form-select"
                size="sm"
                :options="optionsPackage"
                :class="status($v.form.company_package_id)"
                @change="setFormValue('company_package_id', $event)"
              />
              <span v-if="!!errorMessages.form.company_package_id" class="label-error"
                >{{ errorMessages.form.company_package_id }}
              </span>
            </b-form-group>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="m-1">
            <b-form-group
              id="input-group-6"
              label="契約開始月"
              label-for="input-6"
              class="required"
            >
              <month-picker
                id="input-6"
                :max-date="maxStartDate"
                :min-date="null"
                :value="form.company_start_date"
                :on-change="setFormValue"
                :error="!!errorMessages.form.company_start_date"
                property="company_start_date"
              />
              <span v-if="!!errorMessages.form.company_start_date" class="label-error"
                >{{ errorMessages.form.company_start_date }}
              </span>
            </b-form-group>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="m-1">
            <b-form-group
              id="input-group-7"
              label="契約終了月"
              label-for="input-7"
              class="required"
            >
              <month-picker
                id="input-7"
                :min-date="minEndDate"
                :max-date="null"
                :value="form.company_end_date"
                :on-change="setFormValue"
                :error="!!errorMessages.form.company_end_date"
                property="company_end_date"
              />
              <span v-if="!!errorMessages.form.company_end_date" class="label-error"
                >{{ errorMessages.form.company_end_date }}
              </span>
            </b-form-group>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-lg-6">
          <b-form-group id="select-group-3" label="権限" label-for="select-3" class="required">
            <b-form-select
              id="select-3"
              :value="form.role_id"
              class="form-select"
              size="sm"
              :options="optionsRole"
              :class="status($v.form.role_id)"
              @change="setFormValue('role_id', $event)"
            />
            <span v-if="!!errorMessages.form.role_id" class="label-error"
              >{{ errorMessages.form.role_id }}
            </span>
          </b-form-group>
        </div>
        <div class="col-lg-6">
          <b-form-group
            id="select-group-4"
            label="ステータス"
            label-for="select-4"
            class="required"
          >
            <b-form-select
              id="select-4"
              :value="form.company_status"
              class="form-select"
              size="sm"
              :options="optionsStatus"
              :class="status($v.form.company_status)"
              @change="setFormValue('company_status', $event)"
            />
            <span v-if="!!errorMessages.form.company_status" class="label-error"
              >{{ errorMessages.form.company_status }}
            </span>
          </b-form-group>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { email, helpers, required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { MESSAGES } from '@/utils/messages'
import {
  DEFAULT_IMAGE,
  FORM_MODE,
  MAX_FILE_SIZE,
  MAX_LENGTH,
  PASSWORD_DUMMY,
} from '@/utils/constants'
import {
  fileImageToBase64,
  notFreeMail,
  stringToNumberInput,
  validateFileSize,
  validateFileType,
} from '@/utils/function-utils'
import MonthPicker from '@/components/month-picker'

export default {
  name: 'ManagementMemberModalForm',
  components: { MonthPicker },
  props: {
    formData: {
      type: Object,
      default: null,
    },
    triggerValidate: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultLogo: DEFAULT_IMAGE,
      form: {},
      errorMessages: {
        form: {
          company_logo: '',
          company_name: '',
          company_career: '',
          company_phone: '',
          company_domain: '',
          email: '',
          password: '',
          company_package_id: '',
          company_status: '',
          role_id: '',
          company_start_date: '',
          company_end_date: '',
        },
      },
      optionsCareer: [],
      optionsPackage: [],
      optionsStatus: [],
      optionsRole: [],
      allowFileTypes: ['png', 'jpg', 'jpeg'],
      maxStartDate: null,
      minEndDate: null,
      passwordChanged: false,
      dummyPassword: '',
      formMode: FORM_MODE.ADD,
    }
  },
  validations: {
    form: {
      company_name: {
        required,
      },
      company_career: {
        required,
      },
      company_phone: {
        required,
      },
      company_domain: {
        required,
        validDomain: (value) => value.endsWith('.com') || value.endsWith('.co.jp'),
      },
      email: {
        required,
        email,
        // not end with free email
        notFreeMail: notFreeMail,
      },
      password: {
        required,
        // at least: 8 chars, 1 uppercase, 1 lowercase, 1 number and 1 special char
        passwordPolicy: helpers.regex(
          'passwordPolicy',
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
        ),
      },
      company_package_id: {
        required,
      },
      company_start_date: {
        required,
      },
      company_end_date: {
        required,
      },
      company_status: {
        required,
      },
      role_id: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters('commonSettings', ['roles', 'career', 'mt_packages', 'user_status']),
    defaultFormData() {
      return {
        company_logo: null,
        company_name: '',
        company_career: null,
        company_phone: null,
        company_capital: null,
        company_domain: '',
        email: '',
        password: '',
        company_package_id: '',
        company_start_date: '',
        company_end_date: '',
        role_id: '',
        company_status: '',
        system_user: 1,
      }
    },
    checkPasswordChanged() {
      let errorCount = 0
      Object.keys(this.errorMessages.form).forEach((key) => {
        if (this.errorMessages.form[key]) {
          errorCount += 1
        }
      })
      return !this.passwordChanged && errorCount === 1
    },
    isEditForm() {
      return this.formMode === FORM_MODE.EDIT
    },
  },
  watch: {
    form(newValue) {
      this.$emit('update:formData', newValue)
    },
    triggerValidate(newVal) {
      this.$emit('update:isValid', this.isValidForm())
    },
  },
  mounted() {
    this.form = { ...this.defaultFormData }
    this.dummyPassword = this.form.password
    if (this.formData) {
      this.form = { ...this.form, ...this.formData }
      this.dummyPassword = PASSWORD_DUMMY
      this.formMode = FORM_MODE.EDIT
      if (this.form.company_start_date) {
        this.minEndDate = new Date(Date.parse(this.form.company_start_date))
      }
      if (this.form.company_end_date) {
        this.maxStartDate = new Date(Date.parse(this.form.company_end_date))
      }
    }
  },
  created() {
    this.optionsCareer = this.career.map((item) => ({ value: item.id, text: item.name }))
    this.optionsPackage = this.mt_packages.map((item) => ({ value: item.id, text: item.name }))
    this.optionsRole = this.roles
      .filter((role) => role.id === 1)
      .map((item) => ({ value: item.id, text: item.name }))
    this.optionsStatus = this.user_status.map((item) => ({ value: item.id, text: item.name }))
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0]
      this.$refs['upload-image'].value = ''
      this.errorMessages.form.company_logo = ''
      // validate file size
      if (!validateFileSize(file, MAX_FILE_SIZE.IMAGE)) {
        this.errorMessages.form.company_logo = MESSAGES.COMMON.MSG99
        return
      }
      // validate file type
      if (!validateFileType(file, this.allowFileTypes)) {
        this.errorMessages.form.company_logo = MESSAGES.COMMON.MSG23
        return
      }
      fileImageToBase64(file).then((result) => {
        this.form.company_logo = result
      })
    },
    handleClickImage() {
      this.$refs.logoImage.click()
    },
    setValidateErrorMessage(instance, name) {
      // check required
      if (!instance.required) {
        this.errorMessages.form[name] = MESSAGES.COMMON.MSG08
        return
      } else {
        this.errorMessages.form[name] = ''
      }
      // check password
      this.checkPassWord(instance, name)
      // check email
      this.checkEmail(instance, name)
      // check domain
      this.checkDomain(instance, name)
      // check endDate
      this.checkDate()
    },
    setFormValue(name, value) {
      if (value === undefined) {
        return
      }
      this.form[name] = value
      if (name === 'password') {
        this.passwordChanged = true
      }
      if (name === 'company_start_date') {
        this.minEndDate = new Date(Date.parse(value))
      }
      if (name === 'company_end_date') {
        this.maxStartDate = new Date(Date.parse(value))
      }
      const validateInstance = this.$v.form[name]
      validateInstance.$touch()
      this.setValidateErrorMessage(validateInstance, name)
    },
    status(validation, validateDate = false, passwordInput = false) {
      if (passwordInput) {
        return {
          invalid:
            validation.$dirty &&
            validation.$invalid &&
            (this.dummyPassword === '' || this.passwordChanged),
        }
      }
      if (!validateDate) {
        return {
          invalid: validation.$dirty && validation.$invalid,
        }
      }
      return {
        invalid:
          (validation.$dirty && validation.$invalid) ||
          this.errorMessages.form.company_start_date !== '',
      }
    },
    isValidForm() {
      this.$v.form.$touch()
      const isValid = !this.$v.form.$invalid
      this.errorMessages.form.company_logo = ''
      if (!isValid) {
        Object.keys(this.errorMessages.form).forEach((key) => {
          if (key !== 'company_logo') {
            this.setValidateErrorMessage(this.$v.form[key], key)
          }
        })
      }
      return (
        (isValid || this.checkPasswordChanged) && this.errorMessages.form.company_start_date === ''
      )
    },
    checkEmail(instance, name) {
      if (name === 'email') {
        if (!instance.email) {
          this.errorMessages.form[name] = MESSAGES.COMMON.MSG09
        }
        if (!instance.notFreeMail) {
          this.errorMessages.form[name] = MESSAGES.COMMON.MSG10
        }
      } else {
        if (!this.errorMessages.form[name]) {
          this.errorMessages.form[name] = ''
        }
      }
    },
    checkPassWord(instance, name) {
      if (name === 'password' && !instance.passwordPolicy) {
        this.errorMessages.form[name] = MESSAGES.COMMON.MSG07
      } else {
        if (!this.errorMessages.form[name]) {
          this.errorMessages.form[name] = ''
        }
      }
    },
    checkDomain(instance, name) {
      if (name === 'company_domain' && !instance.validDomain) {
        this.errorMessages.form[name] = 'xxx.comまたは xxx.co.jp形式で入力してください。'
      } else {
        if (!this.errorMessages.form[name]) {
          this.errorMessages.form[name] = ''
        }
      }
    },
    checkDate() {
      if (
        this.form.company_start_date &&
        this.form.company_end_date &&
        Date.parse(this.form.company_start_date) > Date.parse(this.form.company_end_date)
      ) {
        this.errorMessages.form.company_start_date = MESSAGES.ADMIN_MT.ADM99
      } else {
        this.errorMessages.form.company_start_date = ''
      }
    },
    formatNumber(e) {
      const value = stringToNumberInput(e)
      return String(value).substring(0, this.maxLength)
    },
    formatNumberPhone(e) {
      const value = stringToNumberInput(e, true)
      return String(value).substring(0, MAX_LENGTH.PHONE_NUMBER)
    },
    formatter(e) {
      return String(e).substring(0, MAX_LENGTH.DEFAULT)
    },
    resetDummyPassword() {
      this.dummyPassword = ''
      this.passwordChanged = true
    },
  },
}
</script>
