import axios from '@/configs/http/internal-api-axios'
import get from 'lodash-es/get'
import { extendMasterRead } from '@/utils/function-utils'

async function getListData(
  perPage = 10,
  currentPage = 1,
  carrier = '',
  packages = '',
  keyword = '',
  isMaster = false
) {
  let url = `/api/v1/mt/users?perPage=${perPage}&currentPage=${currentPage}&career=${carrier}&package=${packages}&keyword=${keyword}`
  url = extendMasterRead(isMaster, url)
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return {
    items: response.data.items,
    total: response.data.total,
  }
}

async function getCustomerInfo(id, isMaster = false) {
  let url = `/api/v1/mt/users/${id}`
  url = extendMasterRead(isMaster, url)
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return response.data
}

async function getUseAppAccount(id) {
  const url = `/api/v1/mt/subscribe-apps/company/${id}`
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return response.data
}

async function getAppAccount(id) {
  const url = `/api/v1/mt/subscribe-apps/company-account/${id}?type=count`
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return get(response, 'data.apps', [])
}

async function createItem(formData) {
  const response = await axios
    .post('/api/v1/mt/users', formData)
    .then((res) => {
      return {
        success: true,
        data: res.data,
      }
    })
    .catch((err) => {
      return {
        success: false,
        data: err.response.data.message,
      }
    })
  return {
    success: response.success,
    data: response.data,
  }
}

async function editItem(formData) {
  const url = `/api/v1/mt/users/${formData.id}`
  const response = await axios
    .patch(url, formData)
    .then((response) => {
      return {
        success: true,
        data: response.data,
      }
    })
    .catch((error) => {
      return {
        success: false,
        data: error.response.data.message,
      }
    })
  return {
    success: response.success,
    data: response.data,
  }
}

async function changePassword(formData, options = {}) {
  let url = `api/v1/reset-password/${options.id}`
  const response = await axios
    .patch(url, formData)
    .then((resp) => {
      return {
        success: true,
        data: resp.data,
      }
    })
    .catch((e) => {
      return {
        success: false,
        data: e.response.data.message,
      }
    })
  return {
    success: response.success,
    data: response.data,
  }
}

function getFields() {
  return [
    { key: 'user_code', label: '顧客ID', sortable: true },
    { key: 'company_logo', label: 'ロゴ', sortable: true },
    { key: 'company_name', label: '会社名', sortable: true },
    { key: 'company_career_name', label: '業界', sortable: true },
    { key: 'company_package_name', label: 'プラン', sortable: true },
    { key: 'use_app', label: '  利用中のサービス数', sortable: true },
    { key: 'company_status', label: 'ステータス', thClass: 'nowrap', sortable: true },
    { key: 'actions', label: '' },
  ]
}

export const managementMemberService = {
  getFields,
  getListData,
  getCustomerInfo,
  getUseAppAccount,
  getAppAccount,
  editItem,
  createItem,
  changePassword,
}
